import { useMemo, useState, useCallback, ReactNode } from 'react';
import { Stack, SxProps, OutlinedInput, Typography } from '@mui/material';
import { noop } from 'lodash';
import Menu, { MenuItemType } from '../Menu';
import Button from '../Button';
import Icon from '../Icon';
import { IconName } from '../Icon/types';
import TruncatedTypography from '../TruncatedTypography';
import { useDialog } from '../../../hooks';
import { DIALOG_IDS } from '../../registeredDialogs/dialogRegistry';
import IconStacked from '../IconStacked';

export interface CardListItemType {
  id: string;
  title: string;
  subTitle?: string;
  headerIcons?: IconName[];
  iconName?: IconName;
  hasIconBackground?: boolean;
  menuItems?: MenuItemType[];
  deleteDialogContent?: ReactNode;
}

interface CardItemProps {
  item: CardListItemType;
  sx?: SxProps;
  isListItem?: boolean;
  renameItem?: (id: string, newName: string) => Promise<boolean>;
  deleteItem?: (id: string) => Promise<boolean>;
  clickItem?: (id: string) => void;
}

const CardItem = ({
  item,
  sx,
  isListItem,
  renameItem,
  deleteItem,
  clickItem,
}: CardItemProps) => {
  const {
    title,
    subTitle,
    iconName,
    headerIcons,
    hasIconBackground,
    menuItems,
    id,
    deleteDialogContent,
  } = item;

  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(title);

  const { showDialog: showDeleteConfirmationDialog } = useDialog({
    id: DIALOG_IDS.CONFIRMATION,
    title: 'Delete',
    contentProps: {
      content: deleteDialogContent ?? (
        <Typography>
          <b>{title}</b> will be permanently erased. This action cannot be
          undone.
        </Typography>
      ),
    },
    primaryAction: {
      text: 'Delete',
      asyncAction: deleteItem
        ? async () => {
            try {
              const success = await deleteItem(id);
              return success;
            } catch (error) {
              // Handle error (e.g., show notification to the user)
              return false;
            }
          }
        : () => Promise.resolve(true),
    },
    secondaryAction: {
      text: 'Cancel',
    },
  });

  const handleRenameClick = () => setIsEditing(true);

  const saveTitleChange = useCallback(async () => {
    if (renameItem) {
      await renameItem(id, editingText);
      setIsEditing(false);
    }
  }, [editingText, id, renameItem]);

  const menuItemsToRender = useMemo<MenuItemType[]>(() => {
    const items: MenuItemType[] = menuItems ? [...menuItems] : [];

    if (renameItem) {
      items.push({
        id: 'rename',
        text: 'Rename',
        onClick: handleRenameClick,
      });
    }

    if (deleteItem) {
      items.push({
        id: 'delete',
        text: 'Delete',
        onClick: showDeleteConfirmationDialog,
        divider: true,
      });
    }

    return items;
  }, [menuItems, renameItem, deleteItem, showDeleteConfirmationDialog]);

  return (
    <Stack
      p={!subTitle ? 1 : 2}
      onClick={isEditing ? noop : () => clickItem?.(id)}
      aria-label={`card-item-${id}`}
      sx={{
        ...sx,
        ...(!isListItem && {
          border: (theme) => `1px solid ${theme.palette.border.light}`,
        }),
        ...(isListItem && {
          borderBottom: (theme) => `1px solid ${theme.palette.border.light}`,
          '&:last-of-type': {
            borderBottom: 'unset',
          },
        }),
        borderRadius: isListItem ? 0 : 3,
        '&:hover': {
          boxShadow: clickItem ? 3 : 'unset',
          cursor: clickItem ? 'pointer' : 'unset',
        },
      }}
    >
      {isEditing ? (
        <EditingView
          id={id}
          editingText={editingText}
          setEditingText={setEditingText}
          setIsEditing={setIsEditing}
          saveTitleChange={saveTitleChange}
        />
      ) : (
        <DisplayView
          id={id}
          title={title}
          subTitle={subTitle}
          iconName={iconName}
          headerIcons={headerIcons}
          hasIconBackground={hasIconBackground}
          menuItemsToRender={menuItemsToRender}
          clickItem={clickItem}
        />
      )}
    </Stack>
  );
};

const EditingView = ({
  id,
  editingText,
  setEditingText,
  setIsEditing,
  saveTitleChange,
}: {
  id: string;
  editingText: string;
  setEditingText: (text: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  saveTitleChange: () => Promise<void>;
}) => (
  <Stack
    direction='row'
    spacing={1.5}
    alignItems='center'
    aria-label={`editing-${id}`}
  >
    <Stack flex={1}>
      <OutlinedInput
        size='small'
        value={editingText}
        onChange={(e) => setEditingText(e.target.value)}
      />
    </Stack>
    <Button
      variant='outlined'
      onClick={(e) => {
        e.stopPropagation();
        setIsEditing(false);
      }}
    >
      Cancel
    </Button>
    <Button
      variant='contained'
      onClick={async (e) => {
        e.stopPropagation();
        await saveTitleChange();
      }}
    >
      Save
    </Button>
  </Stack>
);

const DisplayView = ({
  id,
  title,
  subTitle,
  headerIcons,
  iconName,
  hasIconBackground,
  menuItemsToRender,
  clickItem,
}: {
  id: string;
  title: string;
  subTitle?: string;
  headerIcons?: IconName[];
  iconName?: IconName;
  hasIconBackground?: boolean;
  menuItemsToRender: MenuItemType[];
  clickItem?: (id: string) => void;
}) => {
  const renderCardItemAction = useCallback(() => {
    if (menuItemsToRender.length > 0) {
      return <Menu id='card-item-menu' menuItems={menuItemsToRender} />;
    }

    if (clickItem) {
      return <Icon name='carat-right' size='small' color={'icon.main'} />;
    }

    return null;
  }, [clickItem, menuItemsToRender]);

  const headerIconProps = useMemo(() => {
    if (headerIcons) {
      return headerIcons.map((iconName) => ({
        iconName,
      }));
    }
    return undefined;
  }, [headerIcons]);

  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems={headerIcons ? 'flex-start' : 'center'}
      aria-label={`card-item-display-${id}`}
    >
      <Stack direction='column' spacing={1} width='100%' overflow='hidden'>
        {headerIconProps && <IconStacked icons={headerIconProps} />}
        <Stack direction='row' spacing={1} alignItems='center'>
          {iconName && (
            <Stack
              width={32}
              height={32}
              borderRadius={1}
              bgcolor={hasIconBackground ? 'primary.wash' : 'unset'}
              alignItems='center'
              justifyContent='center'
            >
              <Icon name={iconName} color='icon.main' />
            </Stack>
          )}
          <Stack flex={1} sx={{ overflow: 'hidden' }}>
            <TruncatedTypography variant='body1' color='text.secondary'>
              {title}
            </TruncatedTypography>
            {subTitle && (
              <TruncatedTypography variant='body2' color='text.tertiary'>
                {subTitle}
              </TruncatedTypography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {renderCardItemAction()}
    </Stack>
  );
};

export default CardItem;
