import { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import TextField from '../shared/Inputs/TextField';
import FormActionFooter from '../shared/FormActionFooter';

import { BaseRegisteredDialogComponentProps } from './types';

export interface SaveToWorkspaceProps
  extends BaseRegisteredDialogComponentProps {
  onSubmit: (name: string) => Promise<void>;
  defaultTitle?: string;
}

const SaveToWorkspace = ({
  onSubmit,
  hideDialog,
  defaultTitle,
}: SaveToWorkspaceProps) => {
  const [title, setTitle] = useState(defaultTitle ?? '');
  const handleSubmit = useCallback(() => {
    onSubmit(title);
    hideDialog();
  }, [onSubmit, title, hideDialog]);

  return (
    <Stack spacing={2}>
      <TextField
        label='Title'
        subLabel='Choose a clear, descriptive title that reflects the purpose of this content.'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormActionFooter
        noPadding
        cancelAction={{
          label: 'Cancel',
          action: hideDialog,
        }}
        submitAction={{
          label: 'Save to workspace',
          action: handleSubmit,
          disabled: !title.trim(),
        }}
      />
    </Stack>
  );
};

export default SaveToWorkspace;
