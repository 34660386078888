import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector, useSessionInfo } from '../../hooks';
import WorkspaceNavBar from './LayoutComponents/WorkspaceNavBar';
import { ChatProvider } from '../../contexts/ChatProvider';
import EmptyState from '../shared/EmptyState';
import chartSVG from '../../public/emptyEntities/chart.svg';
import { EnvironmentContext } from '../EnvironmentProvider';

interface WorkspaceLayoutProps {
  children: React.ReactNode;
  workspaceId: string;
}

const WorkspacePageLayout = ({
  children,
  workspaceId,
}: WorkspaceLayoutProps) => {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { workspaceExplores } = useAppSelector(
    (state) => state.workspace.value,
  );

  const { environment } = useContext(EnvironmentContext);
  const environmentName = environment?.name;

  const { userType } = useSessionInfo();
  const isWorkspaceUser = userType === 'WorkspaceMember';

  const showNoWorkspaceDataUI = useMemo(
    () => workspaceExplores?.length === 0,
    [workspaceExplores],
  );

  if (showNoWorkspaceDataUI) {
    const title = 'No data...yet';
    const subTitle = isWorkspaceUser
      ? `Contact ${environmentName} to get started. AI-powered insights await.`
      : 'Authorize data for this workspace to get started.';
    const buttonConfig = isWorkspaceUser
      ? undefined
      : {
          label: 'Manage data',
          onClick: () => {
            const path = `/workspace-settings/manage-data`;
            router.push(path);
          },
        };

    return (
      <EmptyState
        title={title}
        subTitle={subTitle}
        buttonConfig={buttonConfig}
        imagePath={chartSVG}
      />
    );
  }

  return (
    <Stack
      direction={isMobile ? 'column' : 'row-reverse'}
      position='relative'
      height='100%'
    >
      <Stack flex={1} minHeight={0}>
        <ChatProvider workspaceId={workspaceId}>{children}</ChatProvider>
      </Stack>
      <WorkspaceNavBar workspaceId={workspaceId} isMobile={isMobile} />
    </Stack>
  );
};

export default WorkspacePageLayout;
