import { createContext, useEffect, useMemo, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { ApolloError, OperationVariables } from '@apollo/client';
import { useWorkspace } from '../hooks/useWorkspace';
import { useAppSelector } from '../hooks/store';
import { useSessionInfo } from '../hooks/session';
import {
  DeleteIntegrationInput,
  EditWorkspaceMutationVariables,
  DeleteWorkspaceLookerDataModelExploreInput,
  CreateWorkspaceLookerDataModelExploreInput,
  Workspace,
} from '../generated/types';

import WorkspaceVFPPreFetcher from './WorkspaceVFPPrefetcher';
import WorkspaceComponentsPreFetcher from './WorkspaceComponentsPreFetcher';
import MaterializedComponentsBackgroundManager from './WorkspaceStacks/MaterializedComponentsBackgroundManager';

interface WorkspaceContextStore {
  // workspace
  initializeWorkspace: (id: string) => Promise<void>;
  fetchWorkspace: (id: string) => Promise<void>;
  fetchMoreWorkspace: (vars: OperationVariables) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
  editWorkspace: (
    input: EditWorkspaceMutationVariables,
  ) => Promise<Workspace | null>;
  // features
  // integration
  deleteWorkspaceIntegration: (input: DeleteIntegrationInput) => Promise<void>;
  // members
  RECORDS_PER_PAGE_MEMBERS: number;
  // explores
  fetchWorkspaceExplores: (id: string) => Promise<void>;
  createWorkspaceExplore: (
    input: CreateWorkspaceLookerDataModelExploreInput,
  ) => Promise<void>;
  removeExploreFromWorkspace: (
    input: DeleteWorkspaceLookerDataModelExploreInput,
  ) => Promise<void>;
  resetWorkspace: () => Promise<void>;
  isWorkspaceInitialized: boolean;
}

const initialValue: WorkspaceContextStore = {
  // workspace
  initializeWorkspace: async () => {
    /** */
  },
  fetchWorkspace: async () => {
    /** */
  },
  fetchMoreWorkspace: async () => {
    /** */
  },
  loading: false,
  error: undefined,
  editWorkspace: async () => {
    return null;
  },
  deleteWorkspaceIntegration: async () => {
    /** */
  },
  RECORDS_PER_PAGE_MEMBERS: 0,
  fetchWorkspaceExplores: async () => {
    /** */
  },
  createWorkspaceExplore: async () => {
    /** */
  },
  removeExploreFromWorkspace: async () => {
    /** */
  },
  resetWorkspace: async () => {
    /** */
  },
  isWorkspaceInitialized: false,
};

export const WorkspaceContext = createContext(initialValue);

interface WorkspaceProviderProps {
  children: ReactNode;
}

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const { isSignedIn } = useSessionInfo();
  const { workspace } = useAppSelector((store) => store.workspace.value);
  const routerWorkspaceId = useRouter().query.workspaceId as string | undefined;
  const storedWorkspaceId = workspace?.id;

  const {
    // workspace
    isWorkspaceInitialized,
    initializeWorkspace,
    fetchWorkspace,
    fetchMoreWorkspace,
    loading,
    error,
    editWorkspace,
    // features,
    // integrations,
    deleteWorkspaceIntegration,
    // members,
    RECORDS_PER_PAGE_MEMBERS,
    // explores
    fetchWorkspaceExplores,
    createWorkspaceExplore,
    removeExploreFromWorkspace,
    resetWorkspace,
  } = useWorkspace();

  // if new router workspace id is different from stored workspace id,
  // fetch new workspace
  useEffect(() => {
    if (
      isSignedIn &&
      routerWorkspaceId &&
      routerWorkspaceId !== storedWorkspaceId
    ) {
      initializeWorkspace(routerWorkspaceId);
    }
    // Not adding 'storedWorkspaceId' to the dependency list is intentional.
    // This is because 'storedWorkspaceId` is actually the side-effect of
    // what this block of logic produces. Adding it to dependency makes the logic circular.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, routerWorkspaceId]);

  const value = useMemo<WorkspaceContextStore>(
    () => ({
      initializeWorkspace,
      fetchWorkspace,
      fetchMoreWorkspace,
      loading,
      error,
      editWorkspace,
      deleteWorkspaceIntegration,
      RECORDS_PER_PAGE_MEMBERS,
      fetchWorkspaceExplores,
      createWorkspaceExplore,
      removeExploreFromWorkspace,
      resetWorkspace,
      isWorkspaceInitialized,
    }),
    [
      initializeWorkspace,
      fetchWorkspace,
      fetchMoreWorkspace,
      loading,
      error,
      editWorkspace,
      deleteWorkspaceIntegration,
      RECORDS_PER_PAGE_MEMBERS,
      fetchWorkspaceExplores,
      createWorkspaceExplore,
      removeExploreFromWorkspace,
      resetWorkspace,
      isWorkspaceInitialized,
    ],
  );

  return (
    <>
      <WorkspaceContext.Provider value={value}>
        {children}
      </WorkspaceContext.Provider>
      {workspace?.id && (
        <>
          <WorkspaceVFPPreFetcher workspaceId={workspace.id} />
          <WorkspaceComponentsPreFetcher workspaceId={workspace.id} />
          <MaterializedComponentsBackgroundManager workspaceId={workspace.id} />
        </>
      )}
    </>
  );
};

export default WorkspaceProvider;
