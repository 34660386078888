import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Routes } from '@madeinventive/core-types';

// types
import {
  useScheduleVisualizationMutation, // deprecated
  useSaveChatResponseVisualizationMutation,
  WorkspaceComponentsDocument,
  SaveChatResponseVisualizationInput,
  Feature,
} from '../generated/types';
import { ScheduleVizFormValues } from '../components/VisualizationForms/types';

// hooks
import {
  useAppDispatch,
  useToast,
  useSessionInfo,
  useAppSelector,
  useDialogV2,
  useDrawer,
} from '../hooks';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import { DRAWER_IDS } from '../components/registeredDrawers/drawerRegistry';
import { useVisualizationContext } from '../components/Visualization/VisualizationProvider';

// slices
import { addFeature } from '../store/slices/workspace';
import { addNewComponentId } from '../store/slices/workspaceComponents';
import { getExploreInfoByWsExploreId } from '../store/slices/loadedWorkspaceExplores';

// etc
import {
  initialFormValues,
  validationSchema,
} from '../components/VisualizationForms/ScheduleVisualizationForm';

export const useVisualization = (workspaceId: string) => {
  // entity id is either chatResponseId or componentId
  const { entityId, wsExploreId, vizTitle } = useVisualizationContext();
  const { showInfoToast, showDefaultToast, showErrorToast } = useToast();
  const router = useRouter();
  const { email: userEmail } = useSessionInfo();

  const loadedExplores = useAppSelector((store) => store.loadedExplores.value);

  const exploreInfo = getExploreInfoByWsExploreId(
    loadedExplores,
    workspaceId,
    wsExploreId ?? '',
  );

  const envExploreId = exploreInfo?.envExploreId ?? '';

  const storeDispatch = useAppDispatch();

  const [saveChatResponseVisualization] =
    useSaveChatResponseVisualizationMutation({
      refetchQueries: [
        {
          query: WorkspaceComponentsDocument,
          variables: {
            id: workspaceId,
          },
        },
      ],
    });

  const [scheduleVisualization] = useScheduleVisualizationMutation({
    refetchQueries: [
      {
        query: WorkspaceComponentsDocument,
        variables: {
          id: workspaceId,
        },
      },
    ],
  });

  const saveViz = useCallback(
    async ({ chatResponseId, name }: SaveChatResponseVisualizationInput) => {
      const result = await saveChatResponseVisualization({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      });

      if (result.data?.saveChatResponseVisualization.component) {
        const componentId =
          result.data.saveChatResponseVisualization.component.id;
        storeDispatch(addNewComponentId(componentId));
        showDefaultToast('Content saved to workspace.', 'View', () => {
          const path = Routes.chat(
            workspaceId,
            undefined,
            undefined,
            componentId,
          );
          router.push(path);
        });
      } else {
        showErrorToast('Failed to save content to workspace.');
      }
    },
    [
      saveChatResponseVisualization,
      storeDispatch,
      showDefaultToast,
      showErrorToast,
      workspaceId,
      router,
    ],
  );

  const scheduleViz = useCallback(
    async ({
      entityId,
      scheduleVizFormValues,
    }: {
      entityId: string;
      scheduleVizFormValues: ScheduleVizFormValues;
    }) => {
      const result = await scheduleVisualization({
        variables: {
          input: {
            workspaceId,
            entityId,
            scheduleVizConfig: {
              cronSchedule: scheduleVizFormValues.cron,
              to: scheduleVizFormValues.recipients.join(', '),
            },
          },
        },
      });

      const feature = result.data?.scheduleVisualization.feature;
      const component = result.data?.scheduleVisualization.component;

      if (feature && feature.__typename === 'Feature') {
        storeDispatch(addFeature(feature as Feature));
      }

      // if the component is a new component, add it to the store
      if (
        component &&
        component.__typename === 'Component' &&
        entityId !== component.id
      ) {
        storeDispatch(addNewComponentId(component.id));
      }
    },
    [scheduleVisualization, storeDispatch, workspaceId],
  );

  const handleScheduleSubmit = useCallback(
    async (scheduleVizFormValues: ScheduleVizFormValues) => {
      await scheduleViz({
        entityId,
        scheduleVizFormValues,
      });
      showInfoToast('Scheduled.');
    },
    [entityId, scheduleViz, showInfoToast],
  );

  const { showDrawer: showScheduleVizDrawer } = useDrawer({
    id: DRAWER_IDS.SCHEDULE_VISUALIZATION,
    title: 'Schedule',
    isFormikDrawer: true,
    formikProps: {
      initialFormValues: {
        ...initialFormValues,
        recipients: [userEmail],
      },
      submitForm: handleScheduleSubmit,
      validationSchema,
      submitButtonText: 'Schedule',
      validateOnChange: true,
      validateOnBlur: true,
    },
  });

  const { showDrawer: showCreateAlertDrawer } = useDrawer({
    id: DRAWER_IDS.CREATE_ALERT,
    title: 'Create alert',
    contentProps: {
      isDrawer: true,
      workspaceId,
      envExploreId,
      vizTitle,
      entityId,
    },
  });

  const { showDialog } = useDialogV2();

  const showSaveToCreateDialog = useCallback(() => {
    showDialog({
      id: DIALOG_IDS.SAVE_TO_CREATE,
      title: 'Save to Create (admins only)',
      contentProps: {
        chatResponseId: entityId,
      },
    });
  }, [showDialog, entityId]);

  const showSaveToWorkspaceDialog = useCallback(
    (defaultTitle?: string) => {
      showDialog({
        id: DIALOG_IDS.SAVE_TO_WORKSPACE,
        title: 'Save to workspace',
        contentProps: {
          defaultTitle,
          onSubmit: (name) => saveViz({ chatResponseId: entityId, name: name }),
        },
      });
    },
    [showDialog, saveViz, entityId],
  );

  return {
    saveViz,
    scheduleViz,
    showScheduleVizDrawer,
    showCreateAlertDrawer,
    showSaveToCreateDialog,
    showSaveToWorkspaceDialog,
  };
};
